import React from 'react'
import Layout from '../components/Layout'
import AboutComp from '../components/About'
import Header from '../components/Header'
import Banner from '../components/Banner'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function About({ pageContext }) {
  const { t } = useTranslation()

  return (
    <Layout
      className="with-banner about"
      locale={pageContext.language}
      template="about"
    >
      <Header black>
        <Banner
          title={t('page_about_title')}
          className="text-center about-title"
        />
      </Header>
      <main className="white">
        <AboutComp />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
