import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-12 col-sm-12">
            <div className="space-between">
              <div className="img">
                <StaticImage src={'../../assets/images/about.png'} />
              </div>
              <div className="text">
                <h4 className="intro__title section-title">
                  {t('page_about_subtitle_1')}
                </h4>
                <p className="intro__text">
                  <Trans>page_about_paragraph_1</Trans>                
                </p>
                <p className="intro__text">
                  <Trans>page_about_paragraph_11</Trans>                  
                </p>
                <p className="intro__text">
                  <Trans>page_about_paragraph_12</Trans>                  
                </p>
                <p className="intro__text">
                  <Trans>page_about_paragraph_13</Trans>                  
                </p>                             
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-12">
            <h4 className="intro__title section-title mb-60">
              {t('page_about_subtitle_2')}
            </h4>
            <p className="intro__text">
              <Trans>page_about_paragraph_2</Trans>
            </p>
            <p className="intro__text">
              <Trans>page_about_paragraph_21</Trans>
            </p>            
            <p className="intro__text">
              <Trans>page_about_paragraph_22</Trans>
            </p>            
            <p className="intro__text">
              <Trans>page_about_paragraph_23</Trans>
            </p>   
            <p className="intro__text">
              <Trans>page_about_paragraph_24</Trans>
            </p>   
            <p className="intro__text">
              <Trans>page_about_paragraph_25</Trans>
            </p>  
            <p className="intro__text">
              <Trans>page_about_paragraph_26</Trans>
            </p>                                                                 
            <ul>
              <li>{t('page_about_li_1')}</li>
              <li>{t('page_about_li_2')}</li>
              <li>{t('page_about_li_3')}</li>
              <li>{t('page_about_li_4')}</li>
              <li>{t('page_about_li_5')}</li>              
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-5">
            <h4 className="intro__title section-title">
              {t('page_about_subtitle_3')}
            </h4>
            <h3 className="details__title section-title">
              {t('page_about_little_title_graph')}
            </h3>
            <p className="intro__text">{t('page_about_little_paraph_graph')}</p>
            <h3 className="details__title section-title">
              {t('page_about_little_title_dev')}
            </h3>
            <p className="intro__text">{t('page_about_little_paraph_dev')}</p>
          </div>
        </div>     
      </div>
    </>
  )
}

export default About
